import download from 'downloadjs';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

const Util = {
  copyText: (text) => {
    var t = document.createElement("textarea");
    document.body.appendChild(t);
    t.value = text;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
  },
  downloadImage: (url, fileName) => {
    const x = new XMLHttpRequest();

    x.open('GET', url, true);
    x.responseType = 'blob';
    x.onload = (e) => download(e.target.response, fileName, 'image/webp');
    x.send();
  },
  phoneCall: (phone) => {
    if (isMobile) {
      window.location.href = `tel:${phone}`;
    } else {
      toast.info('모바일에서 이용해주세요');
    }
  },
  sendMessage: (phone, message = '') => {
    if (isIOS) {
      window.location.href = `sms:${phone}&body=${message}`;
    } else if (isAndroid) {
      window.location.href = `sms:${phone}?body=${message}`;
    } else {
      toast.info('모바일에서 이용해주세요');
    }
  },
  shareKakao: (title, description, imageUrl, url, views, imageWidth, imageHeight) => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_API_KEY);
    }

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl,
        imageWidth,
        imageHeight,
        link: {
          mobileWebUrl: url,
          webUrl: url
        }
      },
      ...(views !== 0 ? {
        social: {
          viewCount: views,
        }
      } : {})
    });
  }
}

export default Util;