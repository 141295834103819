import ReactModal from 'react-modal';

const DefaultModal = (props: any) => {
  const {children, onSubmit, height} = props;
  
  const style = {
    overlay: {
      zIndex: 3649,
      backgroundColor: '#000000c8',
    },
    content: {
      zIndex: 3650,
      borderRadius: 20,
      top: '50%',
      bottom: 0,
      left: 20,
      right: 20,
      transform: 'translateY(-50%)',
      maxHeight: height,
      height: height,
    }
  }

  if (!onSubmit) {
    throw new Error('onSubmit 필수');
  }

  return (
    <ReactModal style={style} closeTimeoutMS={200} onRequestClose={onSubmit} {...props}>
      <div className="relative w-full h-full flex items-center justify-center">
        <div className="w-full h-full">
          {children}
        </div>
      </div>
    </ReactModal>
  )
}

export default DefaultModal;